<template>
	<div>
		<div v-if="$currentUser.isSubscribed" class="tw-h-full">
			<v-toolbar flat class="tw--ms-1">
				<v-toolbar-title class="tw-text-2xl tw-font-semibold ">
					{{ $t('my-subscription') }}
				</v-toolbar-title>
			</v-toolbar>

			<v-container fluid>
				<SubscriptionCard
					subscribed
					:cancelable="!isCanceled"
					:loading="isUnsubscribing"
					:subscription="subscription"
					@unsubscribe="onUnsubscribe"
				>
					<v-simple-table class="tw-mx-8 tw-border">
						<tbody>
							<tr>
								<th>
									{{ $t('payment') }}
								</th>
								<td>
									<v-chip
										dark
										small
										:color="isPaid ? 'blue' : 'warning'"
									>
										{{ isPaid ? 'Paid' : 'Pending' }}
									</v-chip>
								</td>
							</tr>
							<tr>
								<th>
									{{ $t('expiry-date') }}
								</th>
								<td>
									{{
										$m(buyerSubscription.toEndDate).format(
											'DD-MM-YYYY'
										)
									}}
								</td>
							</tr>
						</tbody>
					</v-simple-table>

					<template #actions>
						<base-button
							v-if="!isPaid"
							:loading="isSubscribing"
							dark
							block
							color="blue"
							@click="onPurchasePackage"
						>
							Make Payment
						</base-button>
					</template>
				</SubscriptionCard>
			</v-container>
		</div>
	</div>
</template>

<script>
import api from '../api'

import { mapActions, mapGetters } from 'vuex'
import { PAYTAB_PAYMENT_TYPE } from '../consts'

import SubscriptionCard from '../components/SubscriptionCard.vue'

export default {
	name: 'Subscription',
	components: { SubscriptionCard },
	data: () => ({
		isSubscribing: false,
		isUnsubscribing: false,
	}),
	created() {
		if (!this.$currentUser.isSubscribed) {
			this.$router.push('/packages')
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		buyerSubscription() {
			return this.$currentUser?.buyerSubscription
		},
		subscription() {
			return this.buyerSubscription.subscription
		},
		isCanceled() {
			return !this.buyerSubscription.status
		},
		payments() {
			return this.buyerSubscription?.order?.payments ?? []
		},
		isPaid() {
			return !this.payments.some((payment) => {
				return payment.paymentStatus === 'pending'
			})
		},
	},
	methods: {
		...mapActions('settings', ['setSettingsState']),
		...mapActions('packages', ['unsubscribe', 'subscribe']),
		async onUnsubscribe(event) {
			this.isUnsubscribing = true
			const [err] = await this.unsubscribe(event)
			this.isUnsubscribing = false

			if (err) {
				return this.$toast.error(
					err.message || `Error while unsubscribing the package.`
				)
			}

			await this.$store.dispatch('auth/fetchProfile')
			// this.$router.push('/packages')
		},
		async onPurchasePackage() {
			this.isSubscribing = true
			let [createErr, createRes] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: this.buyerSubscription.orderId,
				type: PAYTAB_PAYMENT_TYPE.SUBSCRIPTION,
			})
			this.isSubscribing = false
			if (createErr) {
				return this.$toast.error(
					'Error while initializing payment, Please try again.'
				)
			} else {
				this.setSettingsState({
					currentPayment: {
						tranRef: createRes.tranRef,
						type: PAYTAB_PAYMENT_TYPE.SUBSCRIPTION,
					},
				})
			}
			location.replace(createRes.url)
		},
	},
}
</script>
